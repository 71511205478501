<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <a v-if="is('admin') && !showEditForm" href="/#" class="edit-link" @click.prevent="showEditForm = true">
                        <i class="fas fa-edit cursor-pointer"></i> Edit
                    </a>

                    <div class="content-card">
                        <div class="card-body" v-if="!showEditForm" v-html="content.content"></div>

                        <div v-else class="card-body">
                            <div class="col">
                                <div class="row mb-2">
                                    <div class="col pt-2">
                                        <h6>Edit Facility Home Page</h6>
                                    </div>
                                    <div class="col text-right">
                                        <n-button type="success"
                                                  class="m-0 mr-2"
                                                  size="sm"
                                                  round
                                                  :disabled="saving"
                                                  @click.native="saveContent()">Save Changes
                                        </n-button>
                                        <n-button type="info"
                                                  class="m-0"
                                                  size="sm"
                                                  round
                                                  :disabled="saving"
                                                  @click.native="showEditForm = false">Done Editing
                                        </n-button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="card">
                                            <span class="content-card">
                                                <ckeditor :editor="editor" v-model="newContent"></ckeditor>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const ContentRepository = RepositoryFactory.get('content')

export default {
  name: 'facility-home',
  components: {
    ckeditor: CKEditor.component
  },
  data () {
    return {
      editor: ClassicEditor,
      showEditForm: false,
      saving: false,
      newContent: ''
    }
  },
  methods: {
    saveContent () {
      this.saving = true
      ContentRepository.update(this.content.id, { content: this.newContent })
        .then(r => {
          this.showEditForm = false
          this.$store.commit('facility/setContent', r.data)
        })
        .finally(() => {
          this.saving = false
        })
    }
  },
  computed: {
    ...mapState('facility', ['facility', 'content']),
    ...mapGetters({
      is: 'auth/is'
    })
  },
  created () {
    // Set the initial state
    this.newContent = this.$store.state.facility.content.content
  }
}
</script>
<style>

</style>
